<template>
  <section id="footer" class="">
    <v-footer color="primary">
      <v-container class="pa-0 d-flex flex-column">
        <v-row dense>
          <v-col
            cols="12"
            class="
              d-flex
              flex-row
              jusitfy-start
              pl-8 pl-md-0
              text-start
              justify-md-start
              align-center
            "
          >
            <v-img src="@/assets/images/c.png" max-width="150"> </v-img>
          </v-col>
        </v-row>
        <v-row
          dense
          class="color-white-text font-weight-bold"
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'margin-top: 40px;'
              : 'margin-top: 40px;'
          "
        >
          <v-col
            cols="12"
            md="3"
            class="d-flex flex-column align-start pl-8 pl-md-0 text-center"
          >
            <span class="color-green-text">Pasajeros</span>
            <span class="d-flex flex-row align-center mt-2"
              ><a
                class="color-white-text text-decoration-none"
                href="https://play.google.com/store/apps/details?id=com.ridery&hl=es_419&gl=US"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-google-play</v-icon
                >Descargar Google Play</a
              ></span
            >
            <span class="mt-2 d-flex flex-row align-center mr-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://apps.apple.com/ve/app/ridery/id1553877900"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-apple</v-icon>Descargar
                App Store</a
              ></span
            >
            <br />
            <span class="color-green-text">Conductores</span>
            <span class="mt-2 d-flex flex-row align-center mr-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://play.google.com/store/apps/details?id=com.ridery.conductores&hl=es_419&gl=US"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-google-play</v-icon
                >Descargar Google Play Conductor</a
              ></span
            >
            <span class="mt-2 d-flex flex-row align-center mr-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://apps.apple.com/ve/app/ridery-conductores/id1553878292"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-apple</v-icon>Descargar
                App Store Conductor</a
              ></span
            >
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="
              d-flex
              flex-column
              align-start
              pl-8 pl-md-8
              text-start
              mt-8 mt-md-0
            "
          >
            <span class="color-green-text">Aviso Legal</span>
            <span class="mt-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://ridery.app/terms&condition"
                target="_blank"
                >Términos y Condiciones</a
              >
            </span>
            <span class="mt-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://ridery.app/privacy"
                target="_blank"
                >Política de Privacidad</a
              >
            </span>
            <span class="mt-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://ridery.app/providers"
                target="_blank"
                >Política de Conductores</a
              >
            </span>
            <span class="mt-3"
              ><a
                class="color-white-text text-decoration-none"
                href="https://corporativo.ridery.app/parcel-guide"
                target="_blank"
                >Política de Envíos</a
              >
            </span>
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="
              d-flex
              flex-column
              align-start
              pl-8 pl-md-0
              text-start
              mt-8 mt-md-0
            "
          >
            <span class="color-green-text">Contáctanos</span>
            <span class="mt-3"
              ><a
                class="text-decoration-none color-white-text"
                href="https://api.whatsapp.com/send?phone=584128835418&text=Quiero%20hablar%20con%20un%20agente%20Ridery%20%F0%9F%9A%98"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-whatsapp</v-icon
                >WhatsApp</a
              ></span
            >
            <span class="mt-3"
              ><a
                class="text-decoration-none color-white-text"
                href="mailto:viajes@ridery.app"
                target="_blank"
                ><v-icon color="white" class="mr-2">mdi-email-outline</v-icon
                >viajes@ridery.app</a
              ></span
            >
          </v-col>
          <v-col
            cols="12"
            md="3"
            class="
              d-flex
              flex-column
              align-start
              pl-8 pl-md-0
              text-start
              mt-8 mt-md-0
            "
          >
            <span class="color-green-text">Síguenos en redes</span>
            <span class="d-flex flex-row align-center justify-center mt-3">
              <span class="">
                <a
                  class="color-white-text text-decoration-none"
                  href="http://twitter.com/rideryVzla"
                  target="_blank"
                >
                  <v-icon color="white"> mdi-twitter </v-icon>
                </a>
              </span>
              <span class="ml-3">
                <a
                  class="color-white-text text-decoration-none"
                  href="http://instagram.com/rideryapp"
                  target="_blank"
                >
                  <v-icon color="white"> mdi-instagram </v-icon>
                </a>
              </span>
              <span class="ml-3">
                <a
                  class="color-white-text text-decoration-none"
                  href="https://ve.linkedin.com/company/ridery"
                  target="_blank"
                >
                  <v-icon color="white"> mdi-linkedin </v-icon>
                </a>
              </span>
              <span class="ml-3">
                <a
                  class="color-white-text text-decoration-none"
                  href="http://facebook.com/rideryapp"
                  target="_blank"
                >
                  <v-icon color="white"> mdi-facebook </v-icon>
                </a>
              </span>
              <span class="ml-3">
                <a
                  class="color-white-text text-decoration-none"
                  href="https://www.tiktok.com/@rideryvzla"
                  target="_blank"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="#ffffff"
                    class="mt-2"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z"
                    />
                  </svg>
                </a>
              </span>
            </span>
          </v-col>
        </v-row>
        <v-row
          :style="
            $vuetify.breakpoint.mdAndUp
              ? 'margin-top: 60px;'
              : 'margin-top: 30px;'
          "
        >
          <v-col cols="12" class="text-center color-white-text text-sm">
            <span class="font-weight-bold">Ridery</span> © Todos los derechos
            reservados. <br v-if="true" />
            Hecho en Venezuela con
            <span>
              <v-icon class="ms-1" color="success">
                mdi-heart-outline
              </v-icon></span
            >
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </section>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
