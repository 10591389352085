<template>
  <span>
    <!-- hero -->
    <v-container fluid class="pa-0" style="height: 100vh: background-color: #000522;">
      <v-row dense>
        <v-col cols="12">
          <Hero />
        </v-col>
      </v-row>
    </v-container>
    <!-- benefits -->
    <v-container class="pa-0">
      <v-row dense>
        <v-col cols="12">
          <Benefits />
        </v-col>
      </v-row>
    </v-container>
    <!-- transition to services -->
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 75px; overflow: hidden' : 'height: 75px; overflow: hidden'">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C149.99,150.00 242.94,-47.84 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style="stroke: none; fill: #f6f2f2"
        ></path>
      </svg>
    </div>
    <!-- services -->
    <v-container fluid class="pa-0" style="background-color: #f6f2f2">
      <v-container class="pa-0" :fluid="$vuetify.breakpoint.mdAndUp">
        <v-row dense>
          <v-col cols="12">
            <Services />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- transition to locations -->
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 75px; overflow: hidden' : 'height: 25px; overflow: hidden'">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M-26.24,70.56 C241.82,44.91 471.50,222.55 500.00,49.98 L500.00,0.00 L0.00,0.00 Z"
          style="stroke: none; fill: #f6f2f2"
        ></path>
      </svg>
    </div>
    <!-- locations -->
    <v-container fluid class="pa-0">
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <Locations />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- corporate -->
    <v-container fluid class="pa-0">
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <Corporate />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- transition to drivers -->
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 75px; overflow: hidden' : 'height: 50px; overflow: hidden'">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C170.14,80.44 312.36,-49.81 500.00,49.98 L500.00,150.00 L0.00,150.00 Z"
          style="stroke: none; fill: #2b3051"
        ></path>
      </svg>
    </div>
    <!-- drivers -->
    <v-container fluid class="pa-0" style="background-color: #2b3051">
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <Drivers />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- transition to faq -->
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 75px; overflow: hidden' : 'height: 50px; overflow: hidden'">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C269.47,307.41 271.49,-49.98 525.67,113.00 L500.00,0.00 L0.00,0.00 Z"
          style="stroke: none; fill: #2b3051"
        ></path>
      </svg>
    </div>
    <!-- faq -->
    <v-container fluid class="pa-0">
      <v-container class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <Faq />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <!-- transition to footer -->
    <div :style="$vuetify.breakpoint.mdAndUp ? 'height: 75px; overflow: hidden' : 'height: 50px; overflow: hidden'">
      <svg
        viewBox="0 0 500 150"
        preserveAspectRatio="none"
        style="height: 100%; width: 100%"
      >
        <path
          d="M0.00,49.98 C149.99,150.00 355.80,-96.19 606.94,45.89 L500.00,150.00 L0.00,150.00 Z"
          style="stroke: none; fill: #000421"
        ></path>
      </svg>
    </div>
    <!-- footer -->
    <v-container fluid class="pa-0" style="background-color: #000421">
      <v-container fluid class="pa-0">
        <v-row dense>
          <v-col cols="12">
            <Footer />
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </span>
</template>

<script>
import Hero from "@/views/Home/parts/Hero.vue";
import Benefits from "@/views/Home/parts/Benefits.vue";
import Services from "@/views/Home/parts/Services.vue";
import Locations from "@/views/Home/parts/Locations.vue";
import Corporate from "@/views/Home/parts/Corporate.vue";
import Drivers from "@/views/Home/parts/Drivers.vue";
import Faq from "@/views/Home/parts/Faq.vue";
import Footer from "@/views/Home/parts/Footer.vue";

export default {
  name: "Home",

  components: {
    Hero,
    Benefits,
    Services,
    Locations,
    Corporate,
    Drivers,
    Faq,
    Footer,
  },
};
</script>

<style lang="scss" scoped></style>
