<template>
  <section id="corporate">
    <v-container
      class="pa-0 d-flex flex-column justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 50px;' : 'margin-top: 47px;'
      "
    >
    <v-divider></v-divider>

      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: 16px;'
            : 'margin-top: 16px;'
        "
        class="d-flex flex-row align-center"
        ><v-col
          class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start text-start text-md-start"
          cols="12"
          md="5"
          style="font-size: 18px"
        >
          <h1 class="corporateH1 pb-8 color-blue-text">
            <span>Corpo</span><span class="">rativo</span>
          </h1>
          <h2 class="corporateH2 pb-4">Beneficios para tus colaboradores</h2>
          <p class="mt-4 mt-md-0 pb-8">
            <span class="font-weight-bold">Afilia tu organización</span> para
            brindar mayores incentivos a tus colaboradores.
            <span class="color-red-text font-weight-bold"
              >Recibe increíbles paquetes, viajes personalizados</span
            >
            y
            <span class="color-red-text font-weight-bold"
              >respuesta inmediata</span
            >
            a cada requerimiento que necesites en tu empresa.
          </p>
          <span class="d-flex flex-column-reverse flex-md-row">
            <!-- <v-btn
              color="error"
              outlined
              rounded
              class="pa-6 mt-4 mt-md-0"
              @click="handleForm()"
            >
              Registrarse
            </v-btn> -->
            <v-btn
              color="error"
              rounded
              class="pa-6 mt-4 mt-md-0 ml-md-0"
              @click="handleLogin()"
            >
              Iniciar sesión
            </v-btn>
          </span>
        </v-col>
        <v-col
          class="d-flex flex-row justify-start align-start"
          style="padding-bottom: 0px; padding-left: 220px"
          cols="12"
          md="7"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-img
            src="@/views/Home/assets/lapto (1).png"
            v-if="!false"
            max-width="650"
          ></v-img>
        </v-col>
      </v-row>
      <v-row dense class="mt-md-16 mt-16">
        <v-col
          cols="12"
          class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start"
        >
          <h1
            :class="
              $vuetify.breakpoint.mdAndUp ? 'corporateH1 color-blue-text' : 'corporateH1-var color-blue-text'
            "
          >
            <span>Nuestros </span><span class="">clientes</span>
          </h1>
        </v-col>
      </v-row>
      <v-row
        dense
        class=""
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: -20px;'
            : 'margin-top: -50px;'
        "
      >
        <v-col cols="12" class="d-flex flex-column align-center">
          <carousel-3d
            :disable3d="true"
            :clickable="false"
            :controls-visible="true"
            :autoplay="!true"
            :autoplay-timeout="3000"
            :space="$vuetify.breakpoint.mdAndUp ? 365 : 400"
            :width="$vuetify.breakpoint.mdAndUp ? 200 : 200"
            :height="$vuetify.breakpoint.mdAndUp ? 200 : 200"
          >
            <slide v-for="slide in 25" :key="slide" :index="slide - 1">
              <div
                class="d-flex flex-row justify-center align-center"
                style="width: 100%; height: 100%"
              >
                <v-img
                  :src="require(`../assets/clients/Artboard ${slide + 2}.png`)"
                  max-width="180"
                  alt="logo"
                  class="test"
                  :eager="true"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="primary"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  methods: {
    handleLogin() {
      window.open("https://corporativo.ridery.app/login", "_blank");
    },
    handleForm() {
      window.open(
        "https://docs.google.com/forms/d/e/1FAIpQLSfns0imYrCyA6Q5uNUd5W67cGNVVAkOLtwHXBzr87FK0FABCA/viewform",
        "_blank"
      );
    },
  },
};
</script>

<style scoped lang="scss">
.corporateH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
}
.corporateH1-var {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 80px;
}

.carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  top: 0;
  border-radius: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: none;
  // background-size: cover;
  background-color: #ffffff;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .corporateH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 80px;
  }
  .corporateH1-var {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 80px;
  }
  .corporateH2 {
    line-height: 30px;
  }
}
</style>
