<template>
  <section id="drivers">
    <v-container
      class="pa-0 d-flex flex-column justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 24px;' : 'margin-top: 47px;'
      "
    >
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex flex-column align-start pl-8 pl-md-0 text-start text-md-start align-md-start"
        >
          <h1 class="driversH1 color-white-text">
            Conduce <span class="">con</span> Ridery
          </h1>
        </v-col>
      </v-row>
      <v-row dense class="mt-8 mt-md-0">
        <v-col
          cols="12"
          md="5"
          class="d-flex align-start pl-8 pl-md-0 text-start text-md-start"
        >
          <h2 class="driversH2 color-white-text">
            Transforma tu vehículo en una nueva fuente de ingresos.
          </h2>
        </v-col>
      </v-row>
      <v-row dense class="mt-8">
        <v-col
          cols="12"
          class="d-flex align-start pl-8 pl-md-0 text-start text-md-start"
        >
          <a
            class="text-decoration-none"
            href="https://linktr.ee/rideryconductores"
            target="_blank"
          >
            <v-btn color="error" rounded class="pa-6 mt-4 mt-md-0">
              Hazte conductor
            </v-btn>
          </a>
        </v-col>
      </v-row>
      <!-- desktop -->
      <v-row dense style="margin-top: 100px" v-if="$vuetify.breakpoint.mdAndUp">
        <v-col cols="12">
          <div
            style="width: 100%; width: 100%; position: relative"
            class="d-flex flex-row justify-center align-center messages"
          >
            <v-img src="@/views/Home/assets/driversApp.png" class="image125">
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="success"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
            <span class="color-white-text top-left-msg">
              <span class="font-weight-bold"
                >Escoge cuándo y dónde conducir:</span
              >
              <br />
              Decide cuándo aceptar viajes y gana a tu ritmo.
            </span>
            <span class="color-white-text top-right-msg">
              <span class="font-weight-bold">Gana más en alta demanda:</span
              ><br />
              Recibe más ingresos durante las horas con más viajes.
            </span>
            <span class="color-white-text bottom-left-msg">
              <span class="font-weight-bold">Pagos semanales:</span>
              <br />
              Recibe en tu cuenta de conductor las ganancias de todos tus
              viajes.
            </span>
            <span class="color-white-text bottom-right-msg">
              <span class="font-weight-bold">Seguridad en tus viajes:</span>
              <br />
              Calificamos a todos los pasajeros para cuidar de tu integridad en
              todo momento.
            </span>
          </div>
        </v-col>
      </v-row>
      <!-- mobile -->
      <v-row dense v-else style="margin-top: 50px">
        <v-col cols="12" class="d-flex flex-column align-center justify-center">
          <v-img src="@/views/Home/assets/driversApp.png" max-width="350">
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="success"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <carousel-3d
            :disable3d="true"
            :clickable="false"
            :controls-visible="false"
            :autoplay="true"
            :autoplay-timeout="5000"
            :space="$vuetify.breakpoint.mdAndUp ? 365 : 400"
            :width="$vuetify.breakpoint.mdAndUp ? 350 : 350"
            :height="$vuetify.breakpoint.mdAndUp ? 100 : 100"
          >
            <slide :index="0">
              <div class="d-flex flex-column justify-center align-center">
                <span class="color-white-text">
                  <span class="font-weight-bold"
                    >Escoge cuándo y dónde conducir:</span
                  >
                  <br />
                  Decide cuándo aceptar viajes y gana a tu ritmo.
                </span>
              </div>
            </slide>
            <slide :index="1">
              <div class="d-flex flex-column justify-center align-center">
                <span class="color-white-text">
                  <span class="font-weight-bold"
                    >Gana más en alta demanda:</span
                  >
                  <br />
                  Recibe más ingresos durante las horas con más viajes.
                </span>
              </div>
            </slide>
            <slide :index="2">
              <div class="d-flex flex-column justify-center align-center">
                <span class="color-white-text">
                  <span class="font-weight-bold">Pagos semanales:</span>
                  <br />
                  Recibe en tu cuenta de conductor las ganancias de todos tus
                  viajes.
                </span>
              </div>
            </slide>
            <slide :index="3">
              <div class="d-flex flex-column justify-center align-center">
                <span class="color-white-text">
                  <span class="font-weight-bold">Seguridad en tus viajes:</span>
                  <br />
                  Calificamos a todos los pasajeros para cuidar de tu integridad
                  en todo momento.
                </span>
              </div>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp ? 'margin-top: 150px' : 'margin-top: 10px'
        "
        class="d-flex flex-column justify-center align-center"
      >
        <v-col
          cols="12"
          md="6"
          class="d-flex flex-column justify-center align-center"
        >
          <span class="downloadDriver">
            <span class="color-green-text">Descarga la app</span> y comienza
            <br />
            tu registro como conductor.
          </span>
          <span class="d-flex flex-md-row flex-column pt-5 pt-md-10">
            <a
              class="color-white-text text-decoration-none"
              href="https://play.google.com/store/apps/details?id=com.ridery.conductores&hl=es_419&gl=US"
              target="_blank"
            >
              <v-btn
                rounded
                color="success"
                width="216"
                height="52"
                class="d-flex flex-row justify-start align-center"
              >
                <v-icon class="ml-4">mdi-google-play</v-icon>
                <span class="ml-4 font-weight-bold">Google Play</span>
              </v-btn>
            </a>
            <a
              class="color-white-text text-decoration-none"
              href="https://apps.apple.com/ve/app/ridery-conductores/id1553878292"
              target="_blank"
            >
              <v-btn
                class="d-flex flex-row justify-start align-center ml-md-15 mt-md-0 mt-6"
                rounded
                color="success"
                width="216"
                height="52"
              >
                <v-icon class="ml-4 pb-1">mdi-apple</v-icon>
                <span class="ml-6 font-weight-bold">App Store</span>
              </v-btn>
            </a>
          </span>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.driversH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
}
.driversH2 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 30px;
  line-height: 36px;

  color: #000421;
}
.image125 {
  max-width: 750px;
}
.messages {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 100;
  font-size: 25px;
  line-height: 30px;

  // z-index: 100;
}
.top-left-msg {
  position: absolute;
  top: -50px;
  left: 380px;
  width: 300px;
}

.top-right-msg {
  position: absolute;
  top: -25px;
  right: 380px;
  width: 300px;
}

.bottom-left-msg {
  position: absolute;
  top: 580px;
  left: 450px;
  width: 280px;
}

.bottom-right-msg {
  position: absolute;
  top: 280px;
  right: 250px;
  width: 300px;
}

.downloadDriver {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 900;
  font-size: 25px;
  line-height: 30px;
  /* or 120% */

  text-align: center;

  color: #ffffff;
}

.carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  top: 0;
  border-radius: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: none;
  // background-size: cover;
  background-color: #2b3051;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  color: white;
}

@media only screen and (min--moz-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-device-pixel-ratio: 1.25) {
  .top-left-msg {
    position: absolute;
    top: -70px;
    left: 230px;
    width: 300px;
  }
  .top-right-msg {
    position: absolute;
    top: -80px;
    right: 150px;
    width: 300px;
  }
  .bottom-left-msg {
    position: absolute;
    top: 400px;
    left: 230px;
    width: 300px;
  }
  .bottom-right-msg {
    position: absolute;
    top: 250px;
    right: 30px;
    width: 300px;
  }
  .image125 {
    max-width: 500px;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .driversH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 45px;
  }
  .driversH2 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 24px;

    color: #000421;
  }
}
// pantallas medias
@media screen and (min-device-width: 961px) and (max-device-width: 1920px) {
  .top-left-msg {
    position: absolute;
    top: -70px;
    left: 130px;
    width: 300px;
  }
  .top-right-msg {
    position: absolute;
    top: -80px;
    right: 150px;
    width: 300px;
  }
  .bottom-left-msg {
    position: absolute;
    top: 400px;
    left: 230px;
    width: 300px;
  }
  .bottom-right-msg {
    position: absolute;
    top: 250px;
    right: 30px;
    width: 300px;
  }
  .image125 {
    max-width: 500px;
  }
}

@media screen and (min-device-width: 961px) and (max-device-width: 1920px) and (min-device-pixel-ratio: 1.25) {
  .top-left-msg {
    position: absolute;
    top: -70px;
    left: 130px;
    width: 300px;
    background: red;
  }
  .top-right-msg {
    position: absolute;
    top: -80px;
    right: 150px;
    width: 300px;
  }
  .bottom-left-msg {
    position: absolute;
    top: 400px;
    left: 230px;
    width: 300px;
  }
  .bottom-right-msg {
    position: absolute;
    top: 250px;
    right: 30px;
    width: 300px;
  }
  .image125 {
    max-width: 500px;
  }
}
</style>
