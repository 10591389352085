import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: "/category",
    name: "Category",
    component: () =>
      import(
        /* webpackChunkName: "category" */ "../views/Category/Category.vue"
      ),
  },
  {
    path: "/ranking",
    name: "Ranking",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Ranking/Ranking.vue"),
  },
  {
    path: "/top100",
    name: "Ranking2",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/Ranking/Ranking.vue"),
  },
  {
    path: "/delete-account",
    name: "DeleteAccount",
    component: () =>
      import(/* webpackChunkName: "category" */ "../views/DeleteAccount/DeleteAccount.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
