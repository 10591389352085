<template>
  <section id="services">
    <v-container
      class="pa-0 d-flex flex-column justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 0px;' : 'margin-top: 0px;'
      "
      :fluid="$vuetify.breakpoint.mdAndUp"
    >
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex flex-row justify-md-space-between justify-center"
        >
          <div class="marquee" v-if="$vuetify.breakpoint.mdAndUp">
            <div class="marquee__inner">
              <span class="pa-10"
                ><span
                  class="servicesH1 d-flex flex-row justify-center align-center color-blue-text"
                >
                  <span>Ser</span><span class="">vi</span>
                  <span class="">cios</span>
                </span></span
              >
              <span class="pa-10"
                ><span
                  class="servicesH1 d-flex flex-row justify-center align-center color-blue-text"
                >
                  <span>Ser</span><span class="">vi</span>
                  <span class="">cios</span>
                </span></span
              >
              <span class="pa-10"
                ><span
                  class="servicesH1 d-flex flex-row justify-center align-center color-blue-text"
                >
                  <span>Ser</span><span class="">vi</span>
                  <span class="">cios</span>
                </span></span
              >
              <span class="pa-10"
                ><span
                  class="servicesH1 d-flex flex-row justify-center align-center color-blue-text"
                >
                  <span>Ser</span><span class="">vi</span>
                  <span class="">cios</span>
                </span></span
              >
            </div>
          </div>
          <h1
            class="servicesH1 d-flex flex-row justify-start align-center color-blue-text"
            v-else
          >
            <span>Ser</span><span class="">vi</span>
            <span class="">cios</span>
          </h1>
        </v-col>
      </v-row>
      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: -10px;'
            : 'margin-top: -15px;'
        "
        class="d-flex flex-row justify-center align-center"
        ><v-col
          class="d-flex flex-row justify-center align-center"
          cols="12"
          md="11"
        >
          <carousel-3d
            :disable3d="!$vuetify.breakpoint.mdAndUp"
            :controls-visible="true"
            inverse-scaling="1500"
            :space="$vuetify.breakpoint.mdAndUp ? 1200 : 400"
            :width="$vuetify.breakpoint.mdAndUp ? 500 : 300"
            :height="$vuetify.breakpoint.mdAndUp ? 500 : 300"
            :display="3"
            :autoplay-timeout="2500"
            :autoplayHoverPause="true"
          >
            <!-- :autoplay="$vuetify.breakpoint.mdAndUp" -->
            <slide
              :index="item.number"
              v-for="item in carouselItems"
              :key="item.number"
            >
              <div
                style="width: 100%; height: 100%"
                :style="handleStyleVehicle(item.number)"
                class="d-flex flex-column align-start justify-center pa-6 pa-md-6"
              >
                <h2
                  class="carousel-slide-title"
                  :class="`carousel-slide-title-${item.number}`"
                >
                  {{ item.name }}
                </h2>
                <p
                  class="d-flex flex-row align-center justify-center mt-2 mt-md-0"
                >
                  <span
                    class="d-flex flex-row align-center justify-center"
                    style="background-color: #3bd4ae; border-radius: 50%"
                    :style="
                      $vuetify.breakpoint.mdAndUp
                        ? 'width: 60px; height: 60px;  padding: 9.8px;'
                        : 'width: 45px; height: 45px; padding: 0px;'
                    "
                    ><svg
                      :width="$vuetify.breakpoint.mdAndUp ? 35 : 19"
                      :height="$vuetify.breakpoint.mdAndUp ? 25.515 : 14"
                      viewBox="0 0 19 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6.90909 0C7.82529 0 8.70397 0.368749 9.35182 1.02513C9.99968 1.6815 10.3636 2.57174 10.3636 3.5C10.3636 4.42826 9.99968 5.3185 9.35182 5.97487C8.70397 6.63125 7.82529 7 6.90909 7C5.99289 7 5.11421 6.63125 4.46636 5.97487C3.81851 5.3185 3.45455 4.42826 3.45455 3.5C3.45455 2.57174 3.81851 1.6815 4.46636 1.02513C5.11421 0.368749 5.99289 0 6.90909 0ZM6.90909 1.6625C5.90727 1.6625 5.09545 2.485 5.09545 3.5C5.09545 4.515 5.90727 5.3375 6.90909 5.3375C7.3901 5.3375 7.8514 5.14391 8.19153 4.79931C8.53165 4.45471 8.72273 3.98734 8.72273 3.5C8.72273 3.01266 8.53165 2.54529 8.19153 2.20069C7.8514 1.85609 7.3901 1.6625 6.90909 1.6625ZM16.4091 2.625V5.25H19V7H16.4091V9.625H14.6818V7H12.0909V5.25H14.6818V2.625H16.4091ZM6.90909 7.875C9.215 7.875 13.8182 9.03875 13.8182 11.375V14H0V11.375C0 9.03875 4.60318 7.875 6.90909 7.875ZM6.90909 9.5375C4.34409 9.5375 1.64091 10.815 1.64091 11.375V12.3375H12.1773V11.375C12.1773 10.815 9.5 9.5375 6.90909 9.5375Z"
                        fill="black"
                      />
                    </svg>
                    <span
                      class="ml-1 font-weight-bold"
                      :style="
                        $vuetify.breakpoint.mdAndUp
                          ? 'font-size: 18px;'
                          : 'font-size: 16px;'
                      "
                      >{{ item.capacity }}</span
                    >
                  </span>
                  <span
                    class="d-flex flex-row align-center justify-center ml-2"
                    style="background-color: #cecece; border-radius: 50%"
                    :style="
                      $vuetify.breakpoint.mdAndUp
                        ? 'width: 60px; height: 60px; padding: 10px;'
                        : 'width: 45px; height: 45px; padding: 6px;'
                    "
                    ><svg
                      width="35"
                      height="25.515"
                      viewBox="0 0 19 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="mb-1"
                    >
                      <path
                        d="M16.2 3.36842C16.722 3.36842 17.145 3.53684 17.478 3.86526C17.82 4.21053 18 4.58947 18 5.05263V14.3158C18 14.7789 17.82 15.1579 17.478 15.5032C17.145 15.8316 16.722 16 16.2 16H1.8C1.278 16 0.855 15.8316 0.522 15.5032C0.18 15.1579 0 14.7789 0 14.3158V5.05263C0 4.58947 0.18 4.21053 0.522 3.86526C0.855 3.53684 1.278 3.36842 1.8 3.36842H5.4V1.68421C5.4 1.19579 5.58 0.8 5.922 0.488421C6.255 0.168421 6.678 0 7.2 0H10.8C11.322 0 11.745 0.168421 12.078 0.488421C12.42 0.8 12.6 1.19579 12.6 1.68421V3.36842H16.2ZM1.8 5.05263V14.3158H16.2V5.05263H1.8ZM10.8 3.36842V1.68421H7.2V3.36842H10.8Z"
                        fill="black"
                      />
                    </svg>

                    <span
                      class="ml-1 font-weight-bold"
                      style="font-size: 18px"
                      >{{ item.luggage }}</span
                    >
                  </span>
                </p>
                <p class="carousel-slide-subtitle">
                  <span class="font-weight-bold">Desde </span>{{ item.minFare }}
                </p>
                <v-img
                  :width="handleServiceImageWidth(item.number)"
                  :src="item.image"
                  class="carousel-slide-image"
                  :class="`carousel-slide-image-${item.number}`"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="success"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </div>
            </slide>
          </carousel-3d>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
// import axios from "@/plugins/axios";

export default {
  data() {
    return {
      carouselItems: [
        {
          name: "Moto",
          minFare: "$1.25",
          image: require("@/views/Home/assets/fleet/moto.png"),
          capacity: 1,
          luggage: 0,
          number: 0,
        },
        {
          name: "Económico",
          minFare: "$2.70",
          image: require("@/views/Home/assets/fleet/economico.png"),
          capacity: 3,
          luggage: 1,
          number: 1,
        },
        {
          name: "Ridery",
          minFare: "$3.00",
          image: require("@/views/Home/assets/fleet/ridery.png"),
          capacity: 3,
          luggage: 2,
          number: 2,
        },
        {
          name: "Ridery Max",
          minFare: "$4.00",
          image: require("@/views/Home/assets/fleet/rideryMax.png"),
          capacity: 4,
          luggage: 3,
          number: 3,
        },
        {
          name: "Pick Up",
          minFare: "$20.00",
          image: require("@/views/Home/assets/fleet/pickUp.png"),
          capacity: 3,
          luggage: 4,
          number: 4,
        },
        {
          name: "Ellas x Ellas",
          minFare: "$3.00",
          image: require("@/views/Home/assets/fleet/ellasxellas.png"),
          capacity: 3,
          luggage: 1,
          number: 5,
        },
        {
          name: "Vamos Taxi",
          minFare: "$3.00",
          image: require("@/views/Home/assets/fleet/vamosTaxi.png"),
          capacity: 3,
          luggage: 2,
          number: 6,
        },
        {
          name: "Blindado",
          minFare: "$50.00",
          image: require("@/views/Home/assets/fleet/blindado.png"),
          capacity: 4,
          luggage: 2,
          number: 7,
        },
        {
          name: "Vans Pasajeros",
          minFare: "$20.00",
          image: require("@/views/Home/assets/fleet/vansPasajeros.png"),
          capacity: 11,
          luggage: 3,
          number: 8,
        },
        {
          name: "Vans Carga",
          minFare: "$20.00",
          image: require("@/views/Home/assets/fleet/vansCarga.png"),
          capacity: 1,
          luggage: 6,
          number: 9,
        },
      ],
    };
  },
  methods: {
    handleServiceImageWidth(number) {
      switch (number) {
        case 0:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 150;
        case 1:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 115;
        case 2:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 140;
        case 3:
          return this.$vuetify.breakpoint.mdAndUp ? 180 : 120;
        case 4:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 120;
        case 5:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 120;
        case 6:
          return this.$vuetify.breakpoint.mdAndUp ? 170 : 120;
        case 7:
          return this.$vuetify.breakpoint.mdAndUp ? 170 : 120;
        case 8:
          return this.$vuetify.breakpoint.mdAndUp ? 200 : 120;
        case 9:
          return this.$vuetify.breakpoint.mdAndUp ? 190 : 120;
        default:
          return this.$vuetify.breakpoint.mdAndUp ? 290 : 150;
      }
    },
    handleStyleVehicle(number) {
      if (this.$vuetify.breakpoint.mdAndUp) {
        switch (number) {
          case 0:
            return "margin-left: 50px;";
          case 2:
            return "margin-left: 50px;";
          case 3:
            return "margin-left: 50px;";
          case 4:
            return "margin-left: 50px;";
          case 5:
            return "margin-left: 50px;";
          case 6:
            return "margin-left: 50px;";
          default:
            break;
        }
      } else {
        switch (number) {
          case 3:
            return "margin-left: 20px;";
          case 5:
            return "margin-left: 20px;";
          case 6:
            return "margin-left: 20px;";
          default:
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.servicesH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 80px;
  line-height: 40px;
  /* or 32% */
}

.carousel-3d-slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  top: 0;
  border-radius: 1px;
  border-color: rgba(0, 0, 0, 0.4);
  border-style: none;
  // background-size: cover;
  background-color: #f6f2f2;
  display: block;
  margin: 0;
  box-sizing: border-box;
  text-align: left;
  &:hover {
    cursor: pointer;
  }
  .carousel-slide-title {
    &-3 {
      // background-color: red;
      line-height: 70px;
      max-width: 100px;
      margin-bottom: 20px;
    }
    &-5 {
      // background-color: red;
      line-height: 80px;
      max-width: 400px;
      margin-bottom: 20px;
    }
    &-6 {
      // background-color: red;
      line-height: 80px;
      max-width: 400px;
      margin-bottom: 20px;
    }
    &-8 {
      // background-color: red;
      line-height: 70px;
      max-width: 400px;
      margin-bottom: 20px;
    }
    &-9 {
      // background-color: red;
      line-height: 70px;
      max-width: 400px;
      margin-bottom: 20px;
    }
  }
}

.carousel-3d-slide.current {
  // background-color: red;
  .carousel-slide-title {
    color: #3bd4ae;
    transition: color 1.5s;
    &-0 {
      color: #3bd4ae;
      font-size: 100px;
    }
    &-1 {
      color: #ef4370;
    }
    &-2 {
      color: #3bd4ae;
    }
    &-3 {
      color: #000421;
    }
    &-4 {
      color: #3bd4ae;
    }
    &-5 {
      color: #ef4370;
    }
    &-7 {
      color: #000421;
    }
    &-8 {
      color: #3bd4ae;
    }
    &-9 {
      color: #ef4370;
    }
    &-10 {
      color: #000421;
    }
  }
  .carousel-slide-subtitle {
    color: #000421;
    transition: color 1.5s;
  }
}

.carousel-slide-title {
  font-size: 80px;
  color: #cecece;
}

.carousel-slide-subtitle {
  font-size: 40px;
  color: #cecece;
}

.carousel-slide-image {
  position: absolute;
  left: 280px;
  top: 170px;
  z-index: 2;
  // opacity: 0.1;
  // moto
  &-0 {
    // background-color: red !important;
    top: 170px;
    left: 220px;
  }
  &-1 {
    // background-color: red !important;
    top: 198px;
    left: 240px;
  }
  &-2 {
    // background-color: red !important;
    top: 195px;
    left: 240px;
  }
  &-3 {
    // background-color: red !important;
    top: 155px;
    left: 232px;
  }
  &-4 {
    // background-color: red !important;
    top: 192px;
    left: 265px;
  }
  &-5 {
    // background-color: red !important;
    top: 142px;
    left: 235px;
  }
  &-6 {
    // background-color: red !important;
    top: 142px;
    left: 228px;
  }
  &-7 {
    // background-color: red !important;
    top: 192px;
    left: 268px;
  }
  &-8 {
    // background-color: red !important;
    top: 62px;
    left: 208px;
  }
  &-9 {
    // background-color: red !important;
    top: 82px;
    left: 208px;
  }
}

.carousel-3d-container {
  min-height: 1px;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  margin: 20px auto;
  box-sizing: border-box;
  overflow: visible !important;
}

@media only screen and (min--moz-device-pixel-ratio: 1.25),
  (-o-min-device-pixel-ratio: 5/4),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-device-pixel-ratio: 1.25) {
  .carousel-slide-image {
    position: absolute;
    left: 288px;
    top: 170px;
    z-index: 2;
    // opacity: 0.9;
    &-0 {
      // background-color: red !important;
      top: 170px;
      left: 220px;
    }
    &-1 {
      // background-color: red !important;
      top: 198px;
      left: 240px;
    }
    &-2 {
      // background-color: red !important;
      top: 195px;
      left: 240px;
    }
    &-3 {
      // background-color: red !important;
      top: 155px;
      left: 232px;
    }
    &-4 {
      // background-color: red !important;
      top: 192px;
      left: 265px;
    }
    &-5 {
      // background-color: red !important;
      top: 142px;
      left: 235px;
    }
    &-6 {
      // background-color: red !important;
      top: 142px;
      left: 228px;
    }
    &-7 {
      // background-color: red !important;
      top: 192px;
      left: 268px;
    }
    &-8 {
      // background-color: red !important;
      top: 62px;
      left: 208px;
    }
    &-9 {
      // background-color: red !important;
      top: 82px;
      left: 208px;
    }
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .servicesH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 70px;
    line-height: 40px;
    /* or 32% */
  }
  .carousel-slide-title {
    font-size: 50px;
    line-height: 50px;
  }
  .carousel-slide-subtitle {
    font-size: 21px;
  }
  .carousel-slide-image {
    position: absolute;
    left: 155px;
    top: 150px;
    z-index: 2;
    // opacity: 0.9;
    &-0 {
      // background-color: red !important;
      top: 90px;
      left: 140px;
    }
    &-1 {
      // background-color: red !important;
      top: 105px;
      left: 165px;
    }
    &-2 {
      // background-color: red !important;
      top: 115px;
      left: 140px;
    }
    &-3 {
      // background-color: red !important;
      top: 80px;
      left: 138px;
    }
    &-4 {
      // background-color: red !important;
      top: 100px;
      left: 158px;
    }
    &-5 {
      // background-color: red !important;
      top: 75px;
      left: 148px;
    }
    &-6 {
      // background-color: red !important;
      top: 75px;
      left: 148px;
    }
    &-7 {
      // background-color: red !important;
      top: 100px;
      left: 175px;
    }
    &-8 {
      // background-color: red !important;
      top: 15px;
      left: 135px;
    }
    &-9 {
      // background-color: red !important;
      top: 15px;
      left: 135px;
    }
  }

  .carousel-3d-slide.current {
    // background-color: red;
    .carousel-slide-title {
      color: #3bd4ae;
      transition: color 1.5s;
      &-0 {
        color: #3bd4ae;
        font-size: 70px;
      }
      &-1 {
        color: #ef4370;
      }
      &-2 {
        color: #3bd4ae;
        font-size: 60px;
      }
      &-3 {
        color: #000421;
      }
      &-4 {
        color: #3bd4ae;
      }
      &-5 {
        color: #ef4370;
      }
      &-7 {
        color: #000421;
      }
      &-8 {
        color: #3bd4ae;
      }
      &-9 {
        color: #ef4370;
      }
      &-10 {
        color: #000421;
      }
    }
    .carousel-slide-subtitle {
      color: #000421;
      transition: color 1.5s;
    }
  }

  .carousel-3d-slide {
    .carousel-slide-title {
      &-3 {
        // background-color: red;
        line-height: 40px;
        max-width: 100px;
        margin-bottom: 20px;
      }
      &-5 {
        // background-color: red;
        line-height: 45px;
        max-width: 400px;
        margin-bottom: 20px;
      }
      &-6 {
        // background-color: red;
        line-height: 40px;
        max-width: 400px;
        margin-bottom: 20px;
      }
      &-8 {
        // background-color: red;
        line-height: 40px;
        max-width: 400px;
        margin-bottom: 20px;
      }
      &-9 {
        // background-color: red;
        line-height: 40px;
        max-width: 400px;
        margin-bottom: 20px;
      }
    }
  }
}

.marquee {
  position: relative;
  // overflow: hidden;
  --offset: -65vw;
  --move-initial: calc(+25% + var(--offset));
  --move-final: calc(+50% + var(--offset));
}

.marquee__inner {
  width: fit-content;
  display: flex;
  position: relative;
  transform: translate3d(var(--move-initial), 0, 0);
  animation: marquee 5s linear infinite;
  // animation-play-state: paused;
}

.marquee span {
  font-size: 7vw;
  // padding: 0 2vw;
}

.marquee .marquee__inner {
  animation-play-state: running;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}
</style>
