<template>
  <section id="hero">
    <v-container fluid class="pa-0">
      <v-parallax
        :src="$vuetify.breakpoint.mdAndUp ? desktopHero : mobileHero"
        :height="matchHeight"
        alt="hero"
        v-if="!$vuetify.breakpoint.mdAndUp"
      >
        <v-container
          class="pa-0 d-flex flex-column justify-center pt-16"
          style="min-height: 100vh"
        >
          <span
            class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start"
          >
            <h1 class="heroH1">A donde quieras</h1>
            <h1 class="heroH1">en minutos</h1>
            <p class="pt-md-10 heroP pt-5">Descarga la app y comienza</p>
            <p class="heroP">tu primer viaje.</p>
            <span class="d-flex flex-md-row flex-column pt-5 pt-md-10">
              <a
                class="color-white-text text-decoration-none"
                href="https://play.google.com/store/apps/details?id=com.ridery&hl=es_419&gl=US"
                target="_blank"
              >
                <v-btn
                  rounded
                  color="success"
                  width="216"
                  height="52"
                  class="d-flex flex-row justify-start align-center"
                >
                  <v-icon class="ml-4">mdi-google-play</v-icon>
                  <span class="ml-4 font-weight-bold">Google Play</span>
                </v-btn>
              </a>
              <a
                class="color-white-text text-decoration-none"
                href="https://apps.apple.com/ve/app/ridery/id1553877900"
                target="_blank"
              >
                <v-btn
                  class="d-flex flex-row justify-start align-center ml-md-15 mt-md-0 mt-6"
                  rounded
                  color="success"
                  width="216"
                  height="52"
                >
                  <v-icon class="ml-4 pb-1">mdi-apple</v-icon>
                  <span class="ml-6 font-weight-bold">App Store</span>
                </v-btn>
              </a>
            </span>
          </span>
        </v-container>
      </v-parallax>
      <video-bg
        :sources="[require('@/assets/videos/bg_hero.mp4')]"
        :img="require('@/assets/videos/bg_hero.png')"
        v-else
      >
        <v-container
          class="pa-0 d-flex flex-column justify-center pt-16"
          style="min-height: 100vh"
        >
          <span
            class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start"
          >
            <h1 class="heroH1">A donde quieras</h1>
            <h1 class="heroH1">en minutos</h1>
            <p class="pt-md-10 heroP pt-5">Descarga la app y comienza</p>
            <p class="heroP">tu primer viaje.</p>
            <span class="d-flex flex-md-row flex-column pt-5 pt-md-10">
              <v-btn
                rounded
                color="success"
                width="216"
                height="52"
                class="d-flex flex-row justify-start align-center"
              >
                <a
                  class="color-white-text text-decoration-none"
                  href="https://play.google.com/store/apps/details?id=com.ridery&hl=es_419&gl=US"
                  target="_blank"
                >
                  <v-icon class="ml-4">mdi-google-play</v-icon>
                  <span class="ml-4 font-weight-bold">Google Play</span>
                </a>
              </v-btn>
              <v-btn
                class="d-flex flex-row justify-start align-center ml-md-15 mt-md-0 mt-6"
                rounded
                color="success"
                width="216"
                height="52"
              >
                <a
                  class="color-white-text text-decoration-none"
                  href="https://apps.apple.com/ve/app/ridery/id1553877900"
                  target="_blank"
                >
                  <v-icon class="ml-4 pb-1">mdi-apple</v-icon>
                  <span class="ml-6 font-weight-bold">App Store</span></a
                >
              </v-btn>
            </span>
          </span>
        </v-container>
      </video-bg>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "Hero",
  data() {
    return {
      mobileHero: require("@/views/Home/assets/mobileHero.png"),
      desktopHero: require("@/views/Home/assets/desktopHero.png"),
    };
  },
  computed: {
    matchHeight() {
      return Math.round(window.innerHeight);
    },
  },
  methods: {
    handlePlay() {
      window.open(
        "https://play.google.com/store/apps/details?id=com.ridery&hl=es_419&gl=US",
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.hero {
  min-height: 90vh;
  min-width: 100%;
  background: rgba(0, 4, 33, 0.63);
}

.heroH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 100px;
  line-height: 100px;
  color: #ffffff;
}

.heroP {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 25px;

  color: #ffffff;
}

.VideoBg__content {
  display: flex;
  justify-content: center;
  padding: 500px;
}

.v-parallax__image {
  transform: none !important;
  width: 100% !important;
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .heroH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 45px;
    color: #ffffff;
  }

  .heroP {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 18px;

    color: #ffffff;
  }
}
</style>
