<template>
  <v-app>
    <AppBar :color="color" :flat="flat" v-if="$route.path.name === 'home'"/>

    <v-main class="pt-0">
      <v-container fluid class="pa-0">
        <router-view />
      </v-container>
    </v-main>
    <v-scale-transition v-if="$route.path.name === 'home'">
      <v-btn
        fab
        v-show="fab"
        v-scroll="onScroll"
        dark
        fixed
        bottom
        right
        color="secondary"
        @click="toTop"
      >
        <v-icon>mdi-arrow-up</v-icon>
      </v-btn>
    </v-scale-transition>
  </v-app>
</template>

<script>
import AppBar from "@/components/AppBar.vue";

export default {
  name: "App",
  components: {
    AppBar,
  },

  data: () => ({
    color: "",
    flat: null,
    fab: null,
  }),

  created() {
    const top = window.pageYOffset || 0;
    if (top <= 20) {
      this.color = "transparent";
      this.flat = true;
    }
  },
  methods: {
    onScroll(e) {
      if (typeof window === "undefined") return;
      const top = window.pageYOffset || e.target.scrollTop || 0;
      this.fab = top > 20;
      // ajustar color segun seccion
      if (this.$vuetify.breakpoint.mdAndUp) {
        let zoomLevel = Math.round(window.devicePixelRatio * 100);
        // zoom 125%

        if (zoomLevel === 125) {
          // hero
          // if (20 < top && top < 720) {
          //   this.color = "primary";
          // }
          // // beneficios
          // if (720 < top && top < 1272) {
          //   this.color = "success";
          // }
          // // servicios
          // if (1272 < top && top < 2120) {
          //   this.color = "error";
          // }
          // // locaciones
          // if (2120 < top && top < 3050) {
          //   this.color = "primary";
          // }
          // // corporativo
          // if (3050 < top && top < 4187) {
          //   this.color = "error";
          // }
          // // conductores
          // if (4187 < top && top < 5400) {
          //   this.color = "success";
          // }
          // // final
          // if (5400 < top) {
          //   this.color = "primary";
          // }
        }
        if (zoomLevel === 100) {
          // hero
          // if (20 < top && top < 848) {
          //   this.color = "primary";
          // }
          // // beneficios
          // if (848 < top && top < 1437) {
          //   this.color = "success";
          // }
          // // servicios
          // if (1437 < top && top < 2200) {
          //   this.color = "error";
          // }
          // // locaciones
          // if (2200 < top && top < 3100) {
          //   this.color = "primary";
          // }
          // // corporativo
          // if (3100 < top && top < 4200) {
          //   this.color = "error";
          // }
          // // conductores
          // if (4200 < top && top < 5600) {
          //   this.color = "success";
          // }
          // // final
          // if (5600 < top) {
          //   this.color = "primary";
          // }
        }
      } else {
        //mobile
        // console.log(top)
        // hero
        // if (20 < top && top < 601) {
        //   this.color = "primary";
        // }
        // // beneficios
        // if (601 < top && top < 1608) {
        //   this.color = "success";
        // }
        // // servicios
        // if (1608 < top && top < 1979) {
        //   this.color = "error";
        // }
        // // locaciones
        // if (1979 < top && top < 2465) {
        //   this.color = "primary";
        // }
        // // corporativo
        // if (2465 < top && top < 3372) {
        //   this.color = "error";
        // }
        // // conductores
        // if (3372 < top && top < 4512) {
        //   this.color = "success";
        // }
        // // final
        // if (4512 < top) {
        //   this.color = "primary";
        // }
      }
    },
    toTop() {
      this.$vuetify.goTo(0);
    },
  },

  watch: {
    fab(value) {
      if (value) {
        this.color = "secondary";
        this.flat = false;
      } else {
        this.color = "transparent";
        this.flat = true;
      }
    },
  },
};
</script>

<style lang="scss">
.v-main {
  min-width: 100vw;
}
</style>
