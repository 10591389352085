<template>
  <section id="benefits">
    <v-container
      class="pa-0 d-flex flex-column justify-center pb-md-16"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 92px;' : 'margin-top: 47px;'
      "
    >
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start"
        >
          <h1 class="benefitsH1 color-blue-text">
            <span>Bene</span><span class="">ficios</span>
          </h1>
        </v-col>
      </v-row>
      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: 50px;'
            : 'margin-top: 25px;'
        "
        ><v-col class="d-flex flex-row justify-center align-center" cols="12">
          <v-row
            dense
            class="d-flex flex-column flex-md-row justify-center align-center"
          >
            <v-col
              cols="12"
              md="3"
              v-for="(card, index) in cards"
              :key="card.index"
            >
              <div
                class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start card-text pa-2 pa-md-0 text-center text-md-left"
              >
                <v-row dense>
                  <v-col cols="12" :class="index === 0 && 'pt-0'">
                    <div v-html="handleIcon(index)"></div>
                  </v-col>
                </v-row>
                <v-row dense class="pt-7 pt-md-4">
                  <v-col
                    cols="12"
                    class="d-flex flex-column align-start align-md-start"
                  >
                    <span class="font-weight-bold">
                      {{ card.bold }}
                    </span>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col
                    cols="12"
                    class="d-flex flex-column align-start align-md-start text-start"
                  >
                    <span :style="$vuetify.breakpoint.mdAndUp && 'width: 80%'">
                      {{ card.paragraph }}
                    </span>
                  </v-col>
                </v-row>
              </div>
            </v-col>
          </v-row>
        </v-col></v-row
      >
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      cards: [
        {
          icon: require("@/views/Home/assets/star.png"),
          bold: "Calidad y seguridad",
          paragraph:
            "Conductores y vehículos certificados para cuidar tus viaje a las mejores tarifas.",
        },
        {
          icon: require("@/views/Home/assets/car.png"),
          bold: "Viaja a tu modo",
          paragraph:
            "Desde motos hasta cómodas camionetas. Tú eliges cómo moverte.",
        },
        {
          icon: require("@/views/Home/assets/clock.png"),
          bold: "5 minutos para iniciar tus viajes",
          paragraph:
            "Disponibilidad asegurada en tus conductores justo cuando lo necesitas.",
        },
        {
          icon: require("@/views/Home/assets/dollar.png"),
          bold: "Pagos a conveniencia",
          paragraph:
            "Usa el método de pago que prefieras para confirmar tus viajes.",
        },
      ],
    };
  },
  methods: {
    handleIcon(index) {
      switch (index) {
        case 0:
          return `<svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_282_50)">
<path d="M37.3114 44.6886L22.9997 34.1699L8.68791 44.6886L14.183 27.7011L-0.121094 17.2496H17.5391L22.9997 0.233398L28.4602 17.2496H46.1185L31.8163 27.7011L37.3114 44.6886Z" fill="#000421"/>
</g>
<defs>
<clipPath id="clip0_282_50">
<rect width="46" height="46" fill="white"/>
</clipPath>
</defs>
</svg>`;
        case 1:
          return `<svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_120_1245)">
<path d="M38.2294 6.1875C37.7651 4.92478 36.9252 3.83457 35.8226 3.06357C34.7201 2.29257 33.4079 1.87778 32.0625 1.875H12.9375C11.6046 1.87396 10.3031 2.27963 9.20691 3.03781C8.11067 3.796 7.27186 4.87061 6.80251 6.11813L2.75438 16.3125C0.92935 21.3188 -0.00291657 26.6065 6.85416e-06 31.935V37.5H5.62501V43.125H13.125V37.5H31.875V43.125H39.375V37.5H45V31.935C45.001 26.5779 44.0591 21.2625 42.2175 16.2319L38.2294 6.1875ZM31.875 29.0625C31.875 28.3166 32.1713 27.6012 32.6988 27.0738C33.2262 26.5463 33.9416 26.25 34.6875 26.25C35.4334 26.25 36.1488 26.5463 36.6762 27.0738C37.2037 27.6012 37.5 28.3166 37.5 29.0625C37.5 29.8084 37.2037 30.5238 36.6762 31.0512C36.1488 31.5787 35.4334 31.875 34.6875 31.875C33.9416 31.875 33.2262 31.5787 32.6988 31.0512C32.1713 30.5238 31.875 29.8084 31.875 29.0625ZM8.00813 18.3094L12.06 8.11688C12.1256 7.93664 12.2449 7.78085 12.4018 7.67054C12.5587 7.56024 12.7457 7.50071 12.9375 7.5H32.0625C32.2678 7.50511 32.4663 7.57494 32.6296 7.69952C32.7929 7.8241 32.9127 7.99707 32.9719 8.19375L36.9619 18.2325C37.2488 19.0219 37.4963 19.8206 37.7231 20.625H7.27126C7.50001 19.845 7.73251 19.0706 8.00813 18.3094ZM10.3125 31.875C9.56659 31.875 8.85122 31.5787 8.32377 31.0512C7.79632 30.5238 7.50001 29.8084 7.50001 29.0625C7.50001 28.3166 7.79632 27.6012 8.32377 27.0738C8.85122 26.5463 9.56659 26.25 10.3125 26.25C11.0584 26.25 11.7738 26.5463 12.3012 27.0738C12.8287 27.6012 13.125 28.3166 13.125 29.0625C13.125 29.8084 12.8287 30.5238 12.3012 31.0512C11.7738 31.5787 11.0584 31.875 10.3125 31.875Z" fill="#000421"/>
</g>
<defs>
<clipPath id="clip0_120_1245">
<rect width="45" height="45" fill="white"/>
</clipPath>
</defs>
</svg>
`;
        case 2:
          return `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_120_1247)">
<path d="M22 44C9.86883 44 0 34.1312 0 22C0 9.86883 9.86883 0 22 0C34.1312 0 44 9.86883 44 22C44 34.1312 34.1312 44 22 44ZM31.1667 20.1667H23.8333V9.16667H20.1667V23.8333H31.1667V20.1667Z" fill="#000421"/>
</g>
<defs>
<clipPath id="clip0_120_1247">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>`;
        case 3:
          return `<svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_282_48)">
<path d="M22 0C9.86883 0 0 9.86883 0 22C1.1055 51.1463 42.9 51.139 44 22C44 9.86883 34.1312 0 22 0ZM23.8333 31.1667V33C23.8242 35.398 20.1758 35.3962 20.1667 33V31.1667H19.6753C17.7192 31.1667 15.8932 30.1143 14.9123 28.4185C14.4045 27.5403 14.7052 26.4202 15.5797 25.9142C16.4578 25.4027 17.5798 25.707 18.084 26.5815C18.4122 27.1498 19.0208 27.5 19.6735 27.5H23.8315C25.6593 27.6192 26.477 24.6583 24.4842 24.2733L18.909 23.3438C12.4887 22.154 13.871 12.7967 20.1648 12.8333V11C20.1758 8.602 23.8223 8.60383 23.8315 11V12.8333H24.3228C26.279 12.8333 28.105 13.8875 29.0858 15.5833C29.5937 16.4597 29.293 17.5798 28.4185 18.0877C27.5385 18.5955 26.4183 18.2948 25.9142 17.4185C25.586 16.852 24.9773 16.5018 24.3247 16.5018H20.1667C18.3388 16.3827 17.523 19.3435 19.514 19.7285L25.0892 20.658C31.5095 21.8478 30.1272 31.2052 23.8333 31.1685V31.1667Z" fill="#000421"/>
</g>
<defs>
<clipPath id="clip0_282_48">
<rect width="44" height="44" fill="white"/>
</clipPath>
</defs>
</svg>
`;
        default:
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.benefitsH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
}

.card-text {
  font-size: 18px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .benefitsH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 80px;
  }
}
</style>
