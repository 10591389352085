<template>
  <section id="locations">
    <v-container
      class="pa-0 d-flex flex-column justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 92px;' : 'margin-top: 47px;'
      "
    >
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex flex-column align-start pl-8 pl-md-0 align-md-start text-start text-md-start"
        >
          <h1 class="locationsH1 color-blue-text">
            <span>Dónde</span
            ><span class=""> encontrarnos</span>
          </h1>
        </v-col>
      </v-row>
      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: 45px'
            : 'margin-top: 22.5px;'
        "
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-col cols="12" md="4" class="font-locale">
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold "
          >
            <span class="d-flex flex-row align-center">Distrito Capital /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Caracas</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Aragua /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Maracay</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Carabobo /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Valencia</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Lara /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Barquisimeto</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Zulia /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Maracaibo</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Nueva Esparta /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Margarita - Porlamar</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Anzoátegui /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Puerto la Cruz - El Tigre
            </span>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Bolívar /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Puerto Ordaz</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Mérida /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Mérida</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Barinas /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >Barinas</span
            >
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-center font-weight-bold"
          >
            <span class="d-flex flex-row align-center">Táchira /</span>
            <span class="d-flex flex-row align-center color-green-text ml-2"
              >San Cristóbal</span
            >
          </v-col>
        </v-col>
        <v-col
          cols="12"
          md="8"
          class="d-flex flex-row align-center justify-center"
        >
          <v-img
            src="@/views/Home/assets/mapaarreglo-18.png"
            :max-width="$vuetify.breakpoint.mdAndUp ? 785 : 350"
            class=""
          >
            <!-- style="position: absolute; left: 500px;" -->
          </v-img>
        </v-col>
      </v-row>
      <v-row
        dense
        v-else
        class="d-flex flex-column text-start align-start pl-8 text-xl mt-8"
      >
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Distrito C. /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Caracas</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Aragua /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Maracay</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Carabobo /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Valencia</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Lara /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Barquisimeto</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Zulia /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Maracaibo</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Nueva Esparta /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Margarita - Porlamar</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Anzoátegui /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Puerto la Cruz
          </span>
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Bolívar /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Puerto Ordaz</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Mérida /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Mérida</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Barinas /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >Barinas</span
          >
        </v-col>
        <v-col cols="12" class="d-flex flex-row align-center font-weight-bold">
          <span class="d-flex flex-row align-center">Táchira /</span>
          <span class="d-flex flex-row align-center color-green-text ml-2"
            >San Cristóbal</span
          >
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.locationsH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
}

.font-locale {
  font-size: 20px;
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .locationsH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 40px;
  }
}
</style>
