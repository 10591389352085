<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      app
      :disable-resize-watcher="true"
      :color="color === 'transparent' ? 'primary' : color"
      temporary
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title class="title"
              ><v-img src="@/assets/images/a.png" alt="Logo" class="mr-16" max-width="130"
            /></v-list-item-title>
            <!-- <v-list-item-subtitle>Ridery</v-list-item-subtitle> -->
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />
      <v-list dense nav>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item @click="$vuetify.goTo('#hero')">
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                Inicio
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item @click="$vuetify.goTo('#benefits')">
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                Pasajeros
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link>
          <v-list-item-content>
            <v-list-item @click="$vuetify.goTo('#drivers')">
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                Conductores
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link v-if="false">
          <v-list-item-content>
            <v-list-item>
              <v-list-item-content
                class="color-white-text text-xl font-weight-bold"
              >
                ES
              </v-list-item-content>
            </v-list-item>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-app-bar app height="72" :color="color" :flat="flat">
      <v-container
        class="d-flex flex-row justify-lg-space-between align-center"
      >
        <v-toolbar-title
          ><v-img src="@/assets/images/a.png" max-width="130"></v-img
        ></v-toolbar-title>
        <v-spacer></v-spacer>
        <span v-if="$vuetify.breakpoint.mdAndUp">
          <v-btn
            text
            color="white"
            @click="$vuetify.goTo('#hero')"
            class="font-weight-bold"
          >
            Inicio
          </v-btn>
          <v-btn
            class="ml-2 font-weight-bold"
            text
            color="white"
            @click="$vuetify.goTo('#drivers')"
          >
            Conductores
          </v-btn>
          <v-btn
            class="ml-2 font-weight-bold"
            text
            color="white"
            @click="$vuetify.goTo('#benefits')"
          >
            Pasajeros
          </v-btn>
          <template v-if="false">
            <v-divider class="ml-2" vertical></v-divider>
            <v-btn class="ml-2 font-weight-bold" text color="white"> ES </v-btn>
          </template>
        </span>
        <span v-else>
          <v-app-bar-nav-icon color="white" @click="drawer = !drawer">
            <template #default>
              <span v-if="!drawer" class="pt-1 pr-1">
                <svg
                  width="30"
                  height="22"
                  viewBox="0 0 30 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="30" height="3" rx="1.5" fill="white" />
                  <rect
                    x="4"
                    y="9"
                    width="26"
                    height="3"
                    rx="1.5"
                    fill="white"
                  />
                  <rect
                    x="13"
                    y="19"
                    width="17"
                    height="3"
                    rx="1.5"
                    fill="white"
                  />
                </svg>
              </span>
              <span v-else> <v-icon x-large> mdi-close</v-icon> </span>
            </template>
          </v-app-bar-nav-icon>
        </span>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    flat: Boolean,
  },
  data() {
    return {
      drawer: false,
    };
  },
  computed: {
    computedMdAndUp() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
  watch: {
    computedMdAndUp(newVal) {
      if (newVal) {
        this.drawer = false;
      }
    },
  },
};
</script>

<style></style>
