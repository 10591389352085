<template>
  <section id="faq">
    <v-container
      class="pa-0 d-flex flex-column justify-center"
      :style="
        $vuetify.breakpoint.mdAndUp ? 'margin-top: 92px;' : 'margin-top: 47px;'
      "
    >
      <v-row dense>
        <v-col
          cols="12"
          class="d-flex flex-column align-start pl-8 pl-md-0 text-start text-md-start align-md-start"
        >
          <h1 class="faqH1 color-blue-text">
            <span class="">Preguntas </span
            ><span>Frecuentes</span>
          </h1>
        </v-col>
      </v-row>
      <v-row
        dense
        :style="
          $vuetify.breakpoint.mdAndUp
            ? 'margin-top: 51px;'
            : 'margin-top: 51px;'
        "
        class="d-flex flex-row justify-start align-center"
      >
        <v-col
          class="d-flex flex-row justify-center align-center"
          cols="12"
          md="6"
        >
          <v-expansion-panels
            accordion
            eager="true"
            flat
            elevation="0"
            ripple="true"
          >
            <v-expansion-panel
              v-for="(question, index) in questions"
              :key="question.index"
              active-class="activeClass"
            >
              <v-expansion-panel-header>
                <span class="questionTitle">{{ question.question }}</span>
                <template v-slot:actions>
                  <v-icon color="primary" x-large class="questionIcon">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                {{ question.answer }}
                <span v-if="index === 4">
                  <br>
                  <br>
                  <a class="" href="https://linktr.ee/rideryconductores" target="blank">Regístrate como Conductor</a>
                  <br>
                  <br>
                  <a class="" href="https://linktr.ee/ridermoto" target="blank">Regístrate como MotoRider</a>

                </span>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  data() {
    return {
      questions: [
        {
          question: "¿Cómo funciona Ridery?",
          answer:
            "A diferencia de una línea de taxi, Ridery te conecta con conductores certificados para que inicies tu viaje en minutos. Selecciona tu destino, escoge el vehículo que prefieras y comienza a moverte por la ciudad.",
        },
        {
          question: "¿Dónde puedo usar Ridery?",
          answer:
            "Muévete por Caracas, Puerto la cruz, Mérida, Porlamar, El Tigre, Barquisimeto, Valencia, Maracay, Maracaibo, San Cristóbal, Barinas, Puerto Ordaz en un Ridery.",
        },
        {
          question: "¿Cómo se calcula la tarifa de mis viajes?",
          answer:
            "Cuentas con diferentes precios según el tipo de flota y viaje que selecciones. Viajes en moto desde $1.35 y en vehículo Económico a partir de $2.70.",
        },
        {
          question: "¿Por qué la tarifa suele variar de precio?",
          answer:
            "Para asegurar que siempre puedas viajar sin problemas, la tarifa puede ajustarse en ciertas horas del día debido al aumento de usuarios que necesitan viajar y la cantidad de conductores activos.",
        },
        {
          question: "¿Cómo Ridery selecciona sus conductores?",
          answer:
            "Todos los conductores y vehículos pasan por un riguroso proceso de certificación para ofrecerte la mejor calidad y seguridad en tus viajes.",
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.faqH1 {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 800;
  font-size: 60px;
  line-height: 80px;
}
.questionTitle {
  font-family: "Urbanist";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
}

// .v-expansion-panel-header {
//   // background-color: red;
//   display: flex;
//   flex-direction: row;
//   justify-content: start;
//   // text-align: left;
// }
// .v-expansion-panel-header > *:not(.v-expansion-panel-header__icon) {
//   // flex: 1 1 auto;
// }

.activeClass {
  .questionTitle,
  .questionIcon {
    color: #3bd4ae !important;
    transition: color 0.5s ease;
  }
}

@media screen and (min-device-width: 0px) and (max-device-width: 960px) {
  .faqH1 {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 800;
    font-size: 45px;
    line-height: 45px;
  }
  .questionTitle {
    font-family: "Urbanist";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
  }
}
</style>
