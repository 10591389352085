var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{attrs:{"id":"services"}},[_c('v-container',{staticClass:"pa-0 d-flex flex-column justify-center",style:(_vm.$vuetify.breakpoint.mdAndUp ? 'margin-top: 0px;' : 'margin-top: 0px;'),attrs:{"fluid":_vm.$vuetify.breakpoint.mdAndUp}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-md-space-between justify-center",attrs:{"cols":"12"}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"marquee"},[_c('div',{staticClass:"marquee__inner"},[_c('span',{staticClass:"pa-10"},[_c('span',{staticClass:"servicesH1 d-flex flex-row justify-center align-center color-blue-text"},[_c('span',[_vm._v("Ser")]),_c('span',{},[_vm._v("vi")]),_c('span',{},[_vm._v("cios")])])]),_c('span',{staticClass:"pa-10"},[_c('span',{staticClass:"servicesH1 d-flex flex-row justify-center align-center color-blue-text"},[_c('span',[_vm._v("Ser")]),_c('span',{},[_vm._v("vi")]),_c('span',{},[_vm._v("cios")])])]),_c('span',{staticClass:"pa-10"},[_c('span',{staticClass:"servicesH1 d-flex flex-row justify-center align-center color-blue-text"},[_c('span',[_vm._v("Ser")]),_c('span',{},[_vm._v("vi")]),_c('span',{},[_vm._v("cios")])])]),_c('span',{staticClass:"pa-10"},[_c('span',{staticClass:"servicesH1 d-flex flex-row justify-center align-center color-blue-text"},[_c('span',[_vm._v("Ser")]),_c('span',{},[_vm._v("vi")]),_c('span',{},[_vm._v("cios")])])])])]):_c('h1',{staticClass:"servicesH1 d-flex flex-row justify-start align-center color-blue-text"},[_c('span',[_vm._v("Ser")]),_c('span',{},[_vm._v("vi")]),_c('span',{},[_vm._v("cios")])])])],1),_c('v-row',{staticClass:"d-flex flex-row justify-center align-center",style:(_vm.$vuetify.breakpoint.mdAndUp
          ? 'margin-top: -10px;'
          : 'margin-top: -15px;'),attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex flex-row justify-center align-center",attrs:{"cols":"12","md":"11"}},[_c('carousel-3d',{attrs:{"disable3d":!_vm.$vuetify.breakpoint.mdAndUp,"controls-visible":true,"inverse-scaling":"1500","space":_vm.$vuetify.breakpoint.mdAndUp ? 1200 : 400,"width":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,"height":_vm.$vuetify.breakpoint.mdAndUp ? 500 : 300,"display":3,"autoplay-timeout":2500,"autoplayHoverPause":true}},_vm._l((_vm.carouselItems),function(item){return _c('slide',{key:item.number,attrs:{"index":item.number}},[_c('div',{staticClass:"d-flex flex-column align-start justify-center pa-6 pa-md-6",staticStyle:{"width":"100%","height":"100%"},style:(_vm.handleStyleVehicle(item.number))},[_c('h2',{staticClass:"carousel-slide-title",class:`carousel-slide-title-${item.number}`},[_vm._v(" "+_vm._s(item.name)+" ")]),_c('p',{staticClass:"d-flex flex-row align-center justify-center mt-2 mt-md-0"},[_c('span',{staticClass:"d-flex flex-row align-center justify-center",staticStyle:{"background-color":"#3bd4ae","border-radius":"50%"},style:(_vm.$vuetify.breakpoint.mdAndUp
                      ? 'width: 60px; height: 60px;  padding: 9.8px;'
                      : 'width: 45px; height: 45px; padding: 0px;')},[_c('svg',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? 35 : 19,"height":_vm.$vuetify.breakpoint.mdAndUp ? 25.515 : 14,"viewBox":"0 0 19 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M6.90909 0C7.82529 0 8.70397 0.368749 9.35182 1.02513C9.99968 1.6815 10.3636 2.57174 10.3636 3.5C10.3636 4.42826 9.99968 5.3185 9.35182 5.97487C8.70397 6.63125 7.82529 7 6.90909 7C5.99289 7 5.11421 6.63125 4.46636 5.97487C3.81851 5.3185 3.45455 4.42826 3.45455 3.5C3.45455 2.57174 3.81851 1.6815 4.46636 1.02513C5.11421 0.368749 5.99289 0 6.90909 0ZM6.90909 1.6625C5.90727 1.6625 5.09545 2.485 5.09545 3.5C5.09545 4.515 5.90727 5.3375 6.90909 5.3375C7.3901 5.3375 7.8514 5.14391 8.19153 4.79931C8.53165 4.45471 8.72273 3.98734 8.72273 3.5C8.72273 3.01266 8.53165 2.54529 8.19153 2.20069C7.8514 1.85609 7.3901 1.6625 6.90909 1.6625ZM16.4091 2.625V5.25H19V7H16.4091V9.625H14.6818V7H12.0909V5.25H14.6818V2.625H16.4091ZM6.90909 7.875C9.215 7.875 13.8182 9.03875 13.8182 11.375V14H0V11.375C0 9.03875 4.60318 7.875 6.90909 7.875ZM6.90909 9.5375C4.34409 9.5375 1.64091 10.815 1.64091 11.375V12.3375H12.1773V11.375C12.1773 10.815 9.5 9.5375 6.90909 9.5375Z","fill":"black"}})]),_c('span',{staticClass:"ml-1 font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                        ? 'font-size: 18px;'
                        : 'font-size: 16px;')},[_vm._v(_vm._s(item.capacity))])]),_c('span',{staticClass:"d-flex flex-row align-center justify-center ml-2",staticStyle:{"background-color":"#cecece","border-radius":"50%"},style:(_vm.$vuetify.breakpoint.mdAndUp
                      ? 'width: 60px; height: 60px; padding: 10px;'
                      : 'width: 45px; height: 45px; padding: 6px;')},[_c('svg',{staticClass:"mb-1",attrs:{"width":"35","height":"25.515","viewBox":"0 0 19 14","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M16.2 3.36842C16.722 3.36842 17.145 3.53684 17.478 3.86526C17.82 4.21053 18 4.58947 18 5.05263V14.3158C18 14.7789 17.82 15.1579 17.478 15.5032C17.145 15.8316 16.722 16 16.2 16H1.8C1.278 16 0.855 15.8316 0.522 15.5032C0.18 15.1579 0 14.7789 0 14.3158V5.05263C0 4.58947 0.18 4.21053 0.522 3.86526C0.855 3.53684 1.278 3.36842 1.8 3.36842H5.4V1.68421C5.4 1.19579 5.58 0.8 5.922 0.488421C6.255 0.168421 6.678 0 7.2 0H10.8C11.322 0 11.745 0.168421 12.078 0.488421C12.42 0.8 12.6 1.19579 12.6 1.68421V3.36842H16.2ZM1.8 5.05263V14.3158H16.2V5.05263H1.8ZM10.8 3.36842V1.68421H7.2V3.36842H10.8Z","fill":"black"}})]),_c('span',{staticClass:"ml-1 font-weight-bold",staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.luggage))])])]),_c('p',{staticClass:"carousel-slide-subtitle"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Desde ")]),_vm._v(_vm._s(item.minFare)+" ")]),_c('v-img',{staticClass:"carousel-slide-image",class:`carousel-slide-image-${item.number}`,attrs:{"width":_vm.handleServiceImageWidth(item.number),"src":item.image},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"success"}})],1)]},proxy:true}],null,true)})],1)])}),1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }